import $ from 'jquery'

class Datepicker {
    constructor(element) {
        this.inputEl = $(element).find('input')

        if (this.inputEl.length) {
            this.bindDatepicker()
        }
    }

    bindDatepicker() {
        this.inputEl.pickadate({
            min: 1,
            container: '.js-pickadate-container'
        })
    }
}

export default Datepicker