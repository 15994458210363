import $ from 'jquery'

class PrimaryNavigation {
    constructor() {

        this.elements = {
            indexMenuItem: $('.menu__item--has-children > a'), 
            documentBody: $('body'), 
            offcanvasNavicon: $('.c-offcanvas__navicon')
        }

        this.events()
    }

    events() {
        this.elements.indexMenuItem.on('click', this.toggleSubMenu.bind(this))
        this.elements.offcanvasNavicon.on('click', this.closeOffcanvas.bind(this));
    }

    toggleSubMenu(e) {
        e.preventDefault();
        this.elements.documentBody.addClass('sub-nav--is-active')

        $(event.target).parent().addClass('clicked');
    
        $(event.target).closest('ul').find('.menu__item').each(function(){
            if(!$(event.target).hasClass("clicked")){
                $(event.target).removeClass('is-active');
            }
        });
    
        $(event.target).closest('ul').find('.clicked').toggleClass('is-active');
        $(event.target).parent().removeClass('clicked');
    }

    closeOffcanvas(e) {
        this.elements.documentBody.removeClass('navigation--is-active'); 
        $('.menu__item--has-children').removeClass('is-active')
    }
}

export default PrimaryNavigation