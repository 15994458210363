import $ from 'jquery'

class HorizontalScroll {
    constructor(el) {
        el.on('scroll', this.debounce(this.handleScrollEvent, 200))
    }

    handleScrollEvent() {
        $('body').trigger('revealOffcanvas')
    }

    /**
     * Debounce function
     * https://davidwalsh.name/javascript-debounce-function
     */
    debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null
                if (!immediate) func.apply(context, args)
            }
            var callNow = immediate && !timeout
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
            if (callNow) func.apply(context, args)
        };
    }
}

export default HorizontalScroll