export default class TransferTool {
    constructor(){
        this.section = $('.c-transfer-tool')

        this.init()
    }

    init(){
        this.section.each( (i, ele) => {
            this.addCloseButton(ele)
        })
    }

    addCloseButton(ele) {
        let $parent = $(ele)
        let $closeBtn = $('<button class="c-transfer-tool__close"></button>')

        /* ==================================== *\
            html injection & click handler
        \* ==================================== */
        $parent.prepend($closeBtn) // inject close button

        $closeBtn.on('click', e => {
            sessionStorage.setItem('c-transfer-tool__closed', '1')
            $parent.addClass('c-transfer-tool--closed')
        })

        /* ==================================== *\
            session storage
        \* ==================================== */
        if(sessionStorage.getItem('c-transfer-tool__closed') == undefined) {
            return sessionStorage.setItem('c-transfer-tool__closed', '0')
        }

        if(sessionStorage.getItem('c-transfer-tool__closed') == '1') {
            $parent.addClass('c-transfer-tool--closed')
        }
    }
}