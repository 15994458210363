import $ from 'jquery'

class Clamp {
    constructor(nodeSelector, lineLength) {

        this.clampSelectors = [
            {
                cssSelector: nodeSelector,
                lineLength: lineLength
            }
        ];

        this.events()
    }

    events() {
        $.each(this.clampSelectors, function(index, clamp) {
            $(clamp.cssSelector).each(function(index, element) {
                $clamp(element, {clamp: clamp.lineLength});
            });
        });
    }
}

export default Clamp