import Clamp from './modules/Clamp'
import PrimaryNavigation from './modules/PrimaryNavigation'
import PageTransitions from './modules/PageTransitions'
import Paroller from './modules/Paroller'
import HorizontalScroll from './modules/HorizontalScroll'
import Datepicker from './modules/Datepicker'
import TransferTool from './modules/TransferTool'

new PrimaryNavigation()
new HorizontalScroll($('.c-latest-offers'))
new TransferTool()

const offersClamp = new Clamp('.c-latest-offers .c-cta__text p', 2);

const pageLoadTransition = new PageTransitions(
    'transition-fade',
    'transition-slide',
    1000
);

if ($(window).width() > 1024) {
    const rowMediaSliderImageRight = new Paroller('.s-row--media-slider-image-decoration .c-image-container', -0.08)
    const rowMediaSliderImageLeft = new Paroller('.s-row--media-image-decoration-left .c-image-container', -0.05)
    const rowMediaSliderImageLeftMediaItem = new Paroller('.c-media--overflow-negative .c-media__image', -0.03)
    const rowMediaTilesImage = new Paroller('.s-row--media-tiles-image-decoration .c-image--decoration', -0.05)
    const rowsImageCtaIconList = new Paroller('.s-row--image-cta-icon-list .c-image--decoration', 0.1)
    const rowsParallaxImageDecoration = new Paroller('.s-row--parallax-image-decoration .c-image--decoration', 0.1)
    const rowImageMediaFullWidth = new Paroller('.s-row--media-image-decoration-full-width .c-image--decoration', -0.1)
}

$('.js-datepicker').each(function() {
    new Datepicker($(this))
})

// $('a[href="#book"]').on('click', (e) => {
//     e.preventDefault()
//     $('.c-booking-select__options').find('button[data-page-modal]').trigger('click')
// })

// Transfer App JS

$('.tab_reveal').on('click', function() {
    $('.tab_outer').toggleClass('revealed');
    $('.tab_icon').toggleClass('revealed');
    $('.tab_close').toggleClass('revealed');
    $('.tab_body').toggleClass('revealed');
});