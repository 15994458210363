import $ from 'jquery'
require('paroller.js');

class Paroller {
    constructor(element, factor) {
        if (!this.isLegacyBrowser()) {
            $(element).paroller({
                factor: factor,
                type: 'foreground',
                direction: 'vertical',
                transition: 'transform 0.1s linear'
            })
        }
    }

    isLegacyBrowser() {
        return /Trident\/|MSIE/.test(window.navigator.userAgent)
    }
}

export default Paroller