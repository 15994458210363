import $ from 'jquery'

class PageTransitions {
    constructor(primaryTransition, secondaryTransition, transitionDelayInit) {
        this.elements = {
            windowHTML: $('html'),
            documentMainWrap: $('.s-page-wrap'),
            animationEnter: 'js-is-animating', 
            animationLeave: 'js-is-leaving',
            primaryTransition: primaryTransition,
            secondaryTransition: secondaryTransition, 
            transitionDelayInit: transitionDelayInit
        }
        this.events()
    }

    events() {
        this.elements.windowHTML.addClass(this.elements.animationEnter)
        this.elements.documentMainWrap.addClass(this.elements.primaryTransition) 
        this.elements.documentMainWrap.addClass(this.elements.secondaryTransition) 

        setTimeout(() => {
            this.elements.windowHTML.removeClass(this.elements.animationEnter)
            this.elements.windowHTML.addClass(this.elements.animationLeave)
        }, this.elements.transitionDelayInit)
    }
}

export default PageTransitions